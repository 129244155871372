import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import DropdownButton from 'react-bootstrap/DropdownButton';
import toast from 'react-hot-toast';

const landsOptions = [
    {
        label: "Geothermal Springs on Naron 1.5",
        land_id: '1099512958192'
    },
    {
        label: "Geothermal Springs on Eyeke 1.5",
        land_id: '1099512961301'
    },
    {
        label: "Geothermal Springs on Magor 1.5",
        land_id: '1099512959618'
    },
    {
        label: "Geothermal Springs on Kavian 1.5",
        land_id: '1099512960590'
    },
    {
        label: "Geothermal Springs on Neri 1.5",
        land_id: '1099512958249'
    },
    {
        label: "Icy Desert on Naron 1.7",
        land_id: '1099512958705'
    },
    {
        label: "Icy Desert on Naron 1.7",
        land_id: '1099512958896'
    },
    {
        label: "Icy Desert on Neri 1.7",
        land_id: '1099512959058'
    },
    {
        label: "Icy Desert on Eyeke 1.7",
        land_id: '1099512960866'
    },
    {
        label: "Icy Desert on Kavian 1.7",
        land_id: '1099512961273'
    },
    {
        label: "Icy Desert on Magor 1.7",
        land_id: '1099512961387'
    },
    {
        label: "Icy Desert on Veles 1.7",
        land_id: '1099512961451'
    },
    {
        label: "Icy Mountains on Kavian 2.0",
        land_id: '1099512961007'
    },
    {
        label: "Rocky Desert on Kavian 2.0",
        land_id: '1099512961057'
    },
    {
        label: "Icy Mountains on Naron 2.0",
        land_id: '1099512959991'
    },
    {
        label: "Dormant Volcano on Magor 2.5",
        land_id: '1099512959475'
    },
    {
        label: "Mountains on Magor 3.0",
        land_id: '1099512959311'
    },
    {
        label: "Mountains on Kavian 3.0",
        land_id: '1099512959557'
    },
    {
        label: "Mountains on Naron 3.0",
        land_id: '1099512960234'
    },
    {
        label: "Methane Swampland on Kavian 3.5",
        land_id: '1099512960511'
    },
    {
        label: "Sandy Coastline on Eyeke 1.5",
        land_id: '1099512960219'
    },
    {
        label: "Sandy Coastline on Veles 1.5",
        land_id: '1099512958498'
    },
    {
        label: "Sandy Coastline on Naron 1.5",
        land_id: '1099512958237'
    }
];

const DropdownAction = ({ idChoose, transferTo, serverSendMessage }) => {
    const [show, setShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');

    const [idRange, setIdRange] = useState([]);

    const handleClose = () => setShow(false);

    const handleShow = (item) => {
        setIdRange([]);
        setTransferNFT('none');
        setChoosenTypeRarity('none');
        setChoosenTypeStake('none');
        setChoosenTypeTransfer('none');
        setChoosenTypeRedeem('none');
        setinputBagTemplateId('none');
        setinputBagAmount(0);
        setTransferAmount('ทั้งหมด');
        setSelectedItem(item);

        setCPUAmount(0);
        setNETAmount(0);
        setRAMAmount(128);

        setShow(true);
    };

    const parseIdRange = (input) => {
        const result = [];
        const parts = input.split(',');

        parts.forEach(part => {
            if (part.includes('-')) {
                const [start, end] = part.split('-').map(Number);
                for (let i = start; i <= end; i++) {
                    result.push(i);
                }
            } else {
                result.push(Number(part));
            }
        });

        return result;
    };

    const handleIdRange = (event) => {
        const value = event.target.value;
        setIdRange(parseIdRange(value));
    };

    const isArrayOfNumbers = (arr) => {
        return arr.every(item => typeof item === 'number' && !isNaN(item));
    };

    const [choosenTypeTransfer, setChoosenTypeTransfer] = useState('none');
    const [choosenTypeRedeem, setChoosenTypeRedeem] = useState('none');
    const [transferAmount, setTransferAmount] = useState(0);
    const [CPUAmount, setCPUAmount] = useState(0);
    const [NETAmount, setNETAmount] = useState(0);
    const [RAMAmount, setRAMAmount] = useState(0);
    const [transferNFT, setTransferNFT] = useState('none');

    const handleRAMAmount = (event) => {
        const value = event.target.value;
        setRAMAmount(value < 0 ? 0 : value);
    };

    const handleChoosenTypeTransfer = (event) => {
        const value = event.target.value;
        setChoosenTypeTransfer(value);
    };

    const handleChoosenTypeRedeem = (event) => {
        const value = event.target.value;
        setChoosenTypeRedeem(value);
    };

    const handleTrasnferNFT = (event) => {
        const value = event.target.value;
        setTransferNFT(value);
    };

    const handleTrasnferAmount = (event) => {
        const value = event.target.value;
        setTransferAmount(value < 0 ? 0 : value);
    };

    const handleStakeCPUAmount = (event) => {
        const value = event.target.value;
        setCPUAmount(value < 0 ? 0 : value);
    };

    const handleStakeNETAmount = (event) => {
        const value = event.target.value;
        setNETAmount(value < 0 ? 0 : value);
    };

    const [choosenTypeRarity, setChoosenTypeRarity] = useState('none');
    const [choosenTypeStake, setChoosenTypeStake] = useState('none');

    const handleChoosenTypeRarity = (event) => {
        const value = event.target.value;
        setChoosenTypeRarity(value);
    };

    const handleChoosenTypeStake = (event) => {
        const value = event.target.value;
        setChoosenTypeStake(value);
    };

    const [selectLand, setSelectLand] = useState('1099512959058');
    const [inputBagTemplateId, setinputBagTemplateId] = useState('none');
    const [inputBagAmount, setinputBagAmount] = useState(0);

    const isNumber = (value) => {
        return !isNaN(parseFloat(value)) && isFinite(value);
    };

    const checkInput = () => {
        let send = true;
        const task = {
            type: 'web-message',
            name: selectedItem,
            accounts: [],
            details: {}
        };
        if (idChoose.length > 0) {
            task['accounts'] = idChoose;
        } else {
            task['accounts'] = idRange;
        }
        toast.loading('เช็คข้อมูลคำสั่ง...', { duration: 3000 });

        const bypassIdActions = [
            "Mainstake เคลม Vote",
            "Mainstake ซื้อแรม",
            "Mainstake Swap TLM"
        ]

        if (!isArrayOfNumbers(task['accounts'])) {
            toast.error(`กรุณากรอกไอดีให้ถูกต้อง`, { duration: 5000 });
            send = false;
        } else {
            if (task['accounts']) {
                if (!bypassIdActions.includes(selectedItem)) {
                    if (task['accounts'].length === 0) {
                        toast.error(`กรุณาเลือก หรือกรอกไอดี เพื่อสั่งทำงาน`, { duration: 5000 });
                        send = false;
                    }
                }

            } else {
                toast.error(`กรุณาเลือก หรือกรอกไอดี เพื่อสั่งทำงาน`, { duration: 5000 });
                send = false;
            }
        }

        switch (selectedItem) {
            case "Stake (สเตคตัวเอง)": {
                task['details'] = {
                    cpu: CPUAmount,
                    net: NETAmount,
                };
                if (!isNumber(CPUAmount) && !isNumber(NETAmount)) {
                    toast.error(`กรุณากรอกค่า CPU หรือ NET ให้ถูกต้อง`, { duration: 5000 });
                    send = false;
                }
                break;
            }
            case "Stake 1-1": {
                task['details'] = {
                    cpu: CPUAmount,
                    net: NETAmount,
                    transfer: false
                };
                if (!isNumber(CPUAmount) && !isNumber(NETAmount)) {
                    toast.error(`กรุณากรอกค่า CPU หรือ NET ให้ถูกต้อง`, { duration: 5000 });
                    send = false;
                }
                if (choosenTypeStake === 'none') {
                    toast.error(`กรุณาเลือกวิธีการ Stake`, { duration: 5000 });
                    send = false;
                } else {
                    if (choosenTypeStake === 'transfer') {
                        task['details']['transfer'] = true;
                    } else {
                        task['details']['transfer'] = false;
                    }
                }
                break;
            }
            case "UnStake": {
                task['details'] = {
                    cpu: CPUAmount,
                    net: NETAmount,
                };
                if (!isNumber(CPUAmount) && !isNumber(NETAmount)) {
                    toast.error(`กรุณากรอกค่า CPU หรือ NET ให้ถูกต้อง`, { duration: 5000 });
                    send = false;
                }
                break;
            }
            case "สมัครไอดีเกมส์": {
                task['details'] = {
                    land_id: selectLand
                };
                break;
            }
            case "ถอดของขุด": {
                break;
            }
            case "ใส่ของขุด": {
                task['details'] = {
                    rarity: choosenTypeRarity,
                    template_id: inputBagTemplateId,
                    amount: inputBagAmount
                };
                if (choosenTypeRarity === 'none') {
                    if (inputBagTemplateId === 'none') {
                        toast.error(`${selectedItem} : กรุณาเลือกระดับของ หรือกรอกเลขเทมเพลตไอดี เพื่อใส่ของขุด`, { duration: 5000 });
                        send = false;
                    }
                }
                break;
            }
            case "ย้ายแลนด์": {
                task['details'] = {
                    land_id: selectLand
                };
                break;
            }
            case "เคลม TLM": {
                break;
            }
            case "โอน TLM": {
                task['details'] = {
                    transferAmount: transferAmount,
                    type: choosenTypeTransfer
                };
                if (choosenTypeTransfer === 'none') {
                    toast.error(`${selectedItem} : กรุณาเลือกวิธีการโอน TLM`, { duration: 5000 });
                    send = false;
                }
                if (transferAmount === 0) {
                    toast.error(`${selectedItem} : กรุณากรอกจำนวน TLM ที่ต้องการโอน`, { duration: 5000 });
                    send = false;
                }
                break;
            }
            case "โอน WAX": {
                task['details'] = {
                    transferAmount: transferAmount,
                    type: choosenTypeTransfer
                };
                if (choosenTypeTransfer === 'none') {
                    toast.error(`${selectedItem} : กรุณาเลือกวิธีการโอน WAX`, { duration: 5000 });
                    send = false;
                }
                if (transferAmount === 0) {
                    toast.error(`${selectedItem} : กรุณากรอกจำนวน WAX ที่ต้องการโอน`, { duration: 5000 });
                    send = false;
                }
                break;
            }
            case "โอน NFT": {
                task['details'] = {
                    type: choosenTypeTransfer,
                    item: transferNFT,
                };

                if (transferNFT === 'none') {
                    toast.error(`${selectedItem} : กรุณาเลือกประเภทในการโอน NFT`, { duration: 5000 });
                    send = false;
                }
                if (choosenTypeTransfer === 'none') {
                    toast.error(`${selectedItem} : กรุณาเลือกวิธีการโอน NFT`, { duration: 5000 });
                    send = false;
                }
                break;
            }
            case "กระจายของขุด": {
                task['details'] = {
                    template_id: inputBagTemplateId,
                    amount: inputBagAmount
                };
                if (inputBagTemplateId === 'none') {
                    toast.error(`${selectedItem} :  กรุณากรอกเลขเทมเพลตไอดี`, { duration: 5000 });
                    send = false;
                }
                break;
            }
            case "Mainstake กระจายของขุด": {
                task['details'] = {
                    template_id: inputBagTemplateId,
                    amount: inputBagAmount
                };
                if (inputBagTemplateId === 'none') {
                    toast.error(`${selectedItem} :  กรุณากรอกเลขเทมเพลตไอดี`, { duration: 5000 });
                    send = false;
                }
                break;
            }
            case "Mainstake กระจายของขุดพร้อมใส่ของ": {
                task['details'] = {
                    template_id: inputBagTemplateId,
                    amount: inputBagAmount
                };
                if (inputBagTemplateId === 'none') {
                    toast.error(`${selectedItem} :  กรุณากรอกเลขเทมเพลตไอดี`, { duration: 5000 });
                    send = false;
                }
                break;
            }

            case "Swap TLM": {
                task['details'] = {
                    transferAmount: transferAmount
                };
                if (transferAmount === 0) {
                    toast.error(`${selectedItem} : กรุณากรอกจำนวน TLM ที่ต้องการแลก`, { duration: 5000 });
                    send = false;
                }
                break;
            }

            case "Redeem NFT": {
                task['details'] = {
                    template_id: inputBagTemplateId,
                };
                if (inputBagTemplateId === 'none') {
                    toast.error(`${selectedItem} : กรุณาใส่เลข ID ที่ต้องการแลก`, { duration: 5000 });
                    send = false;
                }
                break;
            }

            case "เคลม Vote": {
                break;
            }

            case "Mainstake เคลม Vote": {
                break;
            }

            case "Mainstake Swap TLM": {
                task['details'] = {
                    transferAmount: transferAmount
                };
                if (transferAmount === 0) {
                    toast.error(`${selectedItem} : กรุณากรอกจำนวน TLM ที่ต้องการแลก`, { duration: 5000 });
                    send = false;
                }
                break;
            }

            case "ซื้อแรม": { 
                task['details'] = {
                    buyram: RAMAmount
                };
                if (RAMAmount === 0) {
                    toast.error(`${selectedItem} : กรุณากรอกจำนวน bytes ที่ต้องการซื้อ`, { duration: 5000 });
                    send = false;
                }
                break;
            }

            case "Mainstake ซื้อแรม": { 
                task['details'] = {
                    buyram: RAMAmount
                };
                if (RAMAmount === 0) {
                    toast.error(`${selectedItem} : กรุณากรอกจำนวน bytes ที่ต้องการซื้อ`, { duration: 5000 });
                    send = false;
                }
                break;
            }

            case "Mainstake ซื้อแรมให้บอท": { 
                task['details'] = {
                    buyram: RAMAmount
                };
                if (RAMAmount === 0) {
                    toast.error(`${selectedItem} : กรุณากรอกจำนวน bytes ที่ต้องการซื้อ`, { duration: 5000 });
                    send = false;
                }
                break;
            }

            
            default: break;
        }
        console.log(task);
        console.log(selectLand);
        console.log(idChoose);
        console.log(idRange);

        if (send) {
            toast.loading('กำลังส่งคำสั่ง...', { duration: 3000 });
            serverSendMessage(JSON.stringify(task));
        }
    }

    const renderInputGroup = () => {
        switch (selectedItem) {
            case "สมัครไอดีเกมส์": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">กำหนดแลนด์</InputGroup.Text>
                            <Form.Control
                                placeholder={selectLand}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setSelectLand(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                )
            }
            case "กระจายของขุด": {
                return (
                    <div>
                        ไอดีแรกที่สั่ง จะเป็นไอดีที่กระจายของ
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">เทมเพลต</InputGroup.Text>
                            <Form.Control
                                placeholder={inputBagTemplateId}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setinputBagTemplateId(e.target.value ? e.target.value : 'none')}
                            />
                            <InputGroup.Text id="LandSelect">จำนวน</InputGroup.Text>
                            <Form.Control
                                placeholder={inputBagAmount}
                                type='number'
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setinputBagAmount(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                )
            }
            case "ใส่ของขุด": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">เทมเพลต</InputGroup.Text>
                            <Form.Control
                                placeholder={inputBagTemplateId}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setinputBagTemplateId(e.target.value ? e.target.value : 'none')}
                                disabled={choosenTypeRarity !== 'none' ? true : false}
                            />
                            <InputGroup.Text id="LandSelect">จำนวน</InputGroup.Text>
                            <Form.Control
                                placeholder={inputBagAmount}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setinputBagAmount(e.target.value)}
                                disabled={choosenTypeRarity !== 'none' ? true : false}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">เลือกระดับของ</InputGroup.Text>
                            <Form.Select onChange={handleChoosenTypeRarity} disabled={inputBagTemplateId !== 'none' ? true : false}>
                                <option value="none" >- ยังไม่ได้เลือก -</option>
                                <option value="Abundant">Abundant</option>
                                <option value="Common">Common</option>
                                <option value="Rare">Rare</option>
                                <option value="Epic">Epic</option>
                                <option value="Legendary">Legendary</option>
                                <option value="Mythical">Mythical</option>
                            </Form.Select>
                        </InputGroup>
                    </div>
                )
            }
            case "ถอดของขุด": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                    </div>
                )
            }
            case "ย้ายแลนด์": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">กำหนดแลนด์</InputGroup.Text>
                            <Form.Control
                                placeholder={selectLand}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setSelectLand(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                )
            }
            case "เคลม TLM": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                    </div>
                )
            }
            case "โอน TLM": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        ใส่ ทั้งหมด เพื่อโอน TLM ทั้งหมด
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดจำนวน</InputGroup.Text>
                            <Form.Control
                                placeholder={transferAmount}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                onChange={handleTrasnferAmount}
                            />
                            <InputGroup.Text id="inputId">TLM</InputGroup.Text>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">เลือกวิธีการโอน</InputGroup.Text>
                            <Form.Select onChange={handleChoosenTypeTransfer}>
                                <option value="none" >- ยังไม่ได้เลือก -</option>
                                <option value="1-1">1-1 (บนลงล่าง)</option>
                                <option value="1-1reverse">1-1 แบบย้อนกลับ (ล่างขึ้นบน)</option>
                                <option value="transferout">ไอดีที่กำหนดใน Setting ({transferTo})</option>
                            </Form.Select>
                        </InputGroup>
                    </div>
                )
            }
            case "โอน WAX": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        ใส่ ทั้งหมด เพื่อโอน WAX ทั้งหมด
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดจำนวน</InputGroup.Text>
                            <Form.Control
                                placeholder={transferAmount}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                onChange={handleTrasnferAmount}
                            />
                            <InputGroup.Text id="inputId">WAX</InputGroup.Text>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">เลือกวิธีการโอน</InputGroup.Text>
                            <Form.Select onChange={handleChoosenTypeTransfer}>
                                <option value="none" >- ยังไม่ได้เลือก -</option>
                                <option value="1-1">1-1 (บนลงล่าง)</option>
                                <option value="1-1reverse">1-1 แบบย้อนกลับ (ล่างขึ้นบน)</option>
                                <option value="transferout">ไอดีที่กำหนดใน Setting ({transferTo})</option>
                            </Form.Select>
                        </InputGroup>
                    </div>
                )
            }
            case "โอน NFT": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">เลือกประเภทการโอน</InputGroup.Text>
                            <Form.Select onChange={handleTrasnferNFT}>
                                <option value="none" >- ยังไม่ได้เลือก -</option>
                                <option value="all">ของทั้งหมด</option>
                                <option value="inuse">ของที่ใส่อยู่</option>
                                <option value="notuse">ของที่ไม่ได้ใส่</option>
                            </Form.Select>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">เลือกวิธีการโอน</InputGroup.Text>
                            <Form.Select onChange={handleChoosenTypeTransfer}>
                                <option value="none" >- ยังไม่ได้เลือก -</option>
                                <option value="1-1">1-1 (บนลงล่าง)</option>
                                <option value="1-1reverse">1-1 แบบย้อนกลับ (ล่างขึ้นบน)</option>
                                <option value="transferout">ไอดีที่กำหนดใน Setting ({transferTo})</option>
                            </Form.Select>
                        </InputGroup>
                    </div>
                )
            }
            case "Stake (สเตคตัวเอง)": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดจำนวน CPU</InputGroup.Text>
                            <Form.Control
                                placeholder={CPUAmount}
                                type='number'
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                onChange={handleStakeCPUAmount}
                            />
                            <InputGroup.Text id="inputId">WAX</InputGroup.Text>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดจำนวน NET</InputGroup.Text>
                            <Form.Control
                                placeholder={NETAmount}
                                type='number'
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                onChange={handleStakeNETAmount}
                            />
                            <InputGroup.Text id="inputId">WAX</InputGroup.Text>
                        </InputGroup>
                    </div>
                )
            }
            case "Stake 1-1": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดจำนวน CPU</InputGroup.Text>
                            <Form.Control
                                placeholder={CPUAmount}
                                type='number'
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                onChange={handleStakeCPUAmount}
                            />
                            <InputGroup.Text id="inputId">WAX</InputGroup.Text>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดจำนวน NET</InputGroup.Text>
                            <Form.Control
                                placeholder={NETAmount}
                                type='number'
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                onChange={handleStakeNETAmount}
                            />
                            <InputGroup.Text id="inputId">WAX</InputGroup.Text>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">เลือกวิธีการ Stake</InputGroup.Text>
                            <Form.Select onChange={handleChoosenTypeStake}>
                                <option value="none" >- ยังไม่ได้เลือก -</option>
                                <option value="transfer">wax จะเป็นของบัญชีปลายทาง</option>
                                <option value="nottransfer">wax จะอยู่ในบัญชีที่ stake</option>
                            </Form.Select>
                        </InputGroup>
                    </div>
                )
            }
            case "UnStake": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        ใส่ ทั้งหมด เพื่อ UnStake ทั้งหมด
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดจำนวน CPU</InputGroup.Text>
                            <Form.Control
                                placeholder={CPUAmount}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                onChange={handleStakeCPUAmount}
                            />
                            <InputGroup.Text id="inputId">WAX</InputGroup.Text>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดจำนวน NET</InputGroup.Text>
                            <Form.Control
                                placeholder={NETAmount}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                onChange={handleStakeNETAmount}
                            />
                            <InputGroup.Text id="inputId">WAX</InputGroup.Text>
                        </InputGroup>
                    </div>
                )
            }
            case "Swap TLM": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        ใส่ ทั้งหมด เพื่อ Swap TLM ทั้งหมด
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดจำนวน</InputGroup.Text>
                            <Form.Control
                                placeholder={transferAmount}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                onChange={handleTrasnferAmount}
                            />
                            <InputGroup.Text id="inputId">TLM</InputGroup.Text>
                        </InputGroup>
                    </div>
                )
            }

            case "Mainstake Swap TLM": {
                return (
                    <div>
                        ใส่ ทั้งหมด เพื่อ Swap TLM ทั้งหมด
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดจำนวน</InputGroup.Text>
                            <Form.Control
                                placeholder={transferAmount}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                onChange={handleTrasnferAmount}
                            />
                            <InputGroup.Text id="inputId">TLM</InputGroup.Text>
                        </InputGroup>
                    </div>
                )
            }

            case "Mainstake กระจายของขุด": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดีที่รับของ</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">เทมเพลต</InputGroup.Text>
                            <Form.Control
                                placeholder={inputBagTemplateId}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setinputBagTemplateId(e.target.value ? e.target.value : 'none')}
                            />
                            <InputGroup.Text id="LandSelect">จำนวน</InputGroup.Text>
                            <Form.Control
                                placeholder={inputBagAmount}
                                type='number'
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setinputBagAmount(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                )
            }
            case "Mainstake กระจายของขุดพร้อมใส่ของ": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดีที่รับของ</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">เทมเพลต</InputGroup.Text>
                            <Form.Control
                                placeholder={inputBagTemplateId}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setinputBagTemplateId(e.target.value ? e.target.value : 'none')}
                            />
                            <InputGroup.Text id="LandSelect">จำนวน</InputGroup.Text>
                            <Form.Control
                                placeholder={inputBagAmount}
                                type='number'
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setinputBagAmount(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                )
            }

            case "Redeem NFT": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">กรอก ID ที่ต้องการแลก</InputGroup.Text>
                            <Form.Control
                                placeholder={inputBagTemplateId}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={(e) => setinputBagTemplateId(e.target.value ? e.target.value : 'none')}
                            />
                        </InputGroup>
                    </div>
                )
            }

            case "Mainstake เคลม Vote": {
                return (
                    <div>
                    </div>
                )
            }

            case "เคลม Vote": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                    </div>
                )
            }

            case "ซื้อแรม": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">กรอกจำนวน Bytes</InputGroup.Text>
                            <Form.Control
                                placeholder={RAMAmount}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={handleRAMAmount}
                            />
                            <InputGroup.Text id="inputId">Bytes</InputGroup.Text>
                        </InputGroup>
                    </div>
                )
            }
            case "Mainstake ซื้อแรม": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">กรอกจำนวน Bytes</InputGroup.Text>
                            <Form.Control
                                placeholder={RAMAmount}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={handleRAMAmount}
                            />
                            <InputGroup.Text id="inputId">Bytes</InputGroup.Text>
                        </InputGroup>
                    </div>
                )
            }
            case "Mainstake ซื้อแรมให้บอท": {
                return (
                    <div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputId">กำหนดไอดี</InputGroup.Text>
                            <Form.Control
                                placeholder={idChoose.length > 0 ? "เลือกแล้ว " + idChoose.length + " ไอดี" : ""}
                                aria-label="idChoose"
                                aria-describedby="inputId"
                                disabled={idChoose.length > 0 ? true : false}
                                onChange={handleIdRange}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="LandSelect">กรอกจำนวน Bytes</InputGroup.Text>
                            <Form.Control
                                placeholder={RAMAmount}
                                aria-label="LandSelect"
                                aria-describedby="inputLandId"
                                onChange={handleRAMAmount}
                            />
                            <InputGroup.Text id="inputId">Bytes</InputGroup.Text>
                        </InputGroup>
                    </div>
                )
            }
            default:
                return (
                    <></>
                );
        }
    };

    return (
        <div>
            <div className='dropup-container'>
                <Dropdown drop="up" className="dropup-component">
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        Bot Action
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                            handleShow('สมัครไอดีเกมส์')
                        }}>สมัครไอดีเกมส์</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            handleShow('ย้ายแลนด์')
                        }}>ย้ายแลนด์</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('ใส่ของขุด')
                        }}>ใส่ของขุด</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            handleShow('ถอดของขุด')
                        }}>ถอดของขุด</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('โอน NFT')
                        }}>โอน NFT</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            handleShow('กระจายของขุด')
                        }}>กระจายของขุด</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('Stake (สเตคตัวเอง)')
                        }}>Stake (สเตคตัวเอง)</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            handleShow('Stake 1-1')
                        }}>Stake 1-1</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            handleShow('UnStake')
                        }}>UnStake</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('โอน TLM')
                        }}>โอน TLM</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            handleShow('โอน WAX')
                        }}>โอน WAX</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            handleShow('เคลม TLM')
                        }}>เคลม TLM</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            handleShow('Swap TLM')
                        }}>Swap TLM</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('Redeem NFT')
                        }}>Redeem NFT</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('เคลม Vote')
                        }}>เคลม Vote</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('ซื้อแรม')
                        }}>ซื้อแรม</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown drop="up" className="dropup-component">
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        Mainstake Action
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                            handleShow('Mainstake กระจายของขุด')
                        }}>กระจายของขุด</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            handleShow('Mainstake กระจายของขุดพร้อมใส่ของ')
                        }}>กระจายของขุดพร้อมใส่ของ</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('Mainstake เคลม Vote')
                        }}>เคลม Vote</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('Mainstake ซื้อแรม')
                        }}>ซื้อแรมให้ตัวเอง</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('Mainstake ซื้อแรมให้บอท')
                        }}>ซื้อแรมให้บอท</Dropdown.Item>

                        <Dropdown.Item onClick={() => {
                            handleShow('Mainstake Swap TLM')
                        }}>Swap TLM</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                centered
            >
                <Modal.Header style={{ backgroundColor: '#404040' }} closeButton>
                    <Modal.Title>🛎️Action - {selectedItem}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#343434', fontSize: '1rem' }}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">คำสั่งที่เลือก</InputGroup.Text>
                        <Form.Control
                            placeholder={selectedItem}
                            aria-describedby="basic-addon1"
                            disabled={true}
                        />
                    </InputGroup>
                    {renderInputGroup()}
                    {selectedItem === 'Mainstake กระจายของขุด' && (
                        <div style={{ margin: '1rem' }}>
                            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}><span style={{ fontWeight: 'bolder' }}>ไอดีที่รับของ:</span> {idChoose.length > 0 ? idChoose.join(',') : idRange.join(', ')}</div>
                        </div>
                    )}
                    {!selectedItem.includes('Mainstake') && (
                        <div style={{ margin: '1rem' }}>
                            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}><span style={{ fontWeight: 'bolder' }}>ไอดีที่จะสั่ง:</span> {idChoose.length > 0 ? idChoose.join(',') : idRange.join(', ')}</div>
                        </div>
                    )}


                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: '#404040' }}>
                    <h5>ยืนยันคำสั่ง?</h5>
                    <Button variant="secondary" onClick={handleClose}>
                        ยกเลิก
                    </Button>
                    <Button variant="primary" onClick={checkInput}>ยืนยัน</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DropdownAction;
