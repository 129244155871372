import React from 'react';
import './SumCss.css';
import Image from 'react-bootstrap/Image';
import toast from 'react-hot-toast';

const ItemShow = ({ data, isBag }) => {
  return (
    <div className={isBag ? 'inventory-box-bag' : 'inventory-box'}>
      {data.map((item, index) => (
        <div className="item-container" key={`${item.img_code}-${index}`}>
          {!isBag && <span className="amount">{item.amount}</span>}
          <Image
            // style={{ width: 25, height: 35 }}
            style={{ width: '1.5rem' }}
            src={`https://mediacache.wax.io/${item.img_code}`}
            onClick={() => {
              if (!isBag) {
                navigator.clipboard.writeText(item.template_id);
                toast.success(`copy template id ${item.template_id} to clipboard success!`, { duration: 2000 });
              }
            }}
            rounded
          />
        </div>
      ))}
    </div>
  );
};

export default ItemShow;