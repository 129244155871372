import React, { useState, useEffect } from 'react';
import CustomTable from '../components/CustomTable';
import Header from '../components/Header';
import DropdownAction from '../components/DropdownAction';

import { useSearchParams } from 'react-router-dom';

import useWebSocket from 'react-use-websocket';
import toast from 'react-hot-toast';
import axios from "axios";


const formatGetMinerData = (getMinerRes) => {
  let selfCpu
  let selfNet
  let wax
  if (!getMinerRes.self_delegated_bandwidth) {
    selfCpu = 0
  } else {
    selfCpu = parseFloat(getMinerRes.self_delegated_bandwidth.cpu_weight).toFixed(2)
  }

  if (!getMinerRes.self_delegated_bandwidth) {
    selfNet = 0
  } else {
    selfNet = parseFloat(getMinerRes.self_delegated_bandwidth.net_weight).toFixed(2)
  }

  if (!getMinerRes.wax_balance) {
    wax = 0
  } else {
    wax = parseFloat(getMinerRes.wax_balance).toFixed(2)
  }

  if (!getMinerRes.land) {
    getMinerRes.land = {
      data: {
        name: 'not register',
        commission: 10000
      }
    }
  }

  if (!getMinerRes.last_mine) {
    getMinerRes.last_mine = "2020-01-01T00:00:00"
  }

  if (!getMinerRes.inventory) {
    getMinerRes.inventory = [];
  }

  let lastminetlm = "";
  if (lastMineState[getMinerRes.last_mine_tx]) {
    lastminetlm = lastMineState[getMinerRes.last_mine_tx];
  } else {
    const getCacheTLM = localStorage.getItem(`${getMinerRes.miner}`);
    if (getCacheTLM) {
      const [lasttx, bounty] = getCacheTLM.split(":");
      if (lasttx === getMinerRes.last_mine_tx) lastminetlm = bounty;
    }
  }

  const now = Date.now();
  const last_mine_ms = Date.parse(getMinerRes.last_mine + '.000Z');
  let waitsec = parseInt((last_mine_ms + (getMinerRes.params.delay * 1000) - now) / 1000);
  let latesec = parseInt(now - (last_mine_ms + (getMinerRes.params.delay * 1000))) / 1000;

  return {
    NO: getMinerRes.id !== "MAINSTAKE" ? parseInt(getMinerRes.id) : getMinerRes.id,
    Account: getMinerRes.miner,
    CPU: getMinerRes.cpu_limit.current_used * 100 / getMinerRes.cpu_limit.max,
    NET: getMinerRes.net_limit.current_used * 100 / getMinerRes.net_limit.max,
    RAM: getMinerRes.ram_usage * 100 / getMinerRes.ram_quota,
    SCPU: [selfCpu, (parseFloat(getMinerRes.total_resources.cpu_weight) - selfCpu).toFixed(2)],
    SNET: [selfNet, (parseFloat(getMinerRes.total_resources.net_weight) - selfNet).toFixed(2)],
    BAL: [parseFloat(getMinerRes.token_tlm).toFixed(2), parseFloat(getMinerRes.claim_tlm).toFixed(2), getMinerRes.claim_tlm_time + '.000Z'],
    WAX: [wax, getMinerRes.refund],
    Land: `${getMinerRes.land.data.name}(${parseFloat(getMinerRes.land.data.commission / 100).toFixed(1)}%)`, // Fixed Land name
    Bags: getMinerRes.bags.map(item => {
      return { img_code: item.data.img, amount: 1 }
    }),
    Pts: [getMinerRes.points > 0 ? getMinerRes.points / 10 : getMinerRes.points, getMinerRes.total_points > 0 ? getMinerRes.total_points / 10 : getMinerRes.total_points / 10],
    LastMine: getMinerRes.last_mine,
    Delay: waitsec < 0 ? "" : waitsec,
    Wait: latesec < 0 ? "" : latesec,
    Status: getMinerRes.status,
    Inventory: getMinerRes.inventory.map(nft => {
      return {
        "template_id": nft.template_id,
        "img_code": nft.item,
        "amount": nft.amount
      }
    }),
    flagged: getMinerRes.flagged,
    lastminetlm: lastminetlm
  };
};

const resultData = (miners) => {

  const returnAllResult = {
    NO: "RESULT",
    Account: "",
    CPU: "",
    NET: "",
    RAM: "",
    SCPU: [0, 0],
    SNET: [0, 0],
    BAL: [0, ""],
    WAX: [0, ""],
    Land: ``,
    Bags: [],
    Pts: [0, 0],
    LastMine: "",
    Delay: 0,
    Wait: 0,
    Status: "",
    Inventory: [],
    flagged: false
  };
  // miners.map(m => {
  //   let selfCpu
  //   let selfNet
  //   let wax
  //   if (!m.self_delegated_bandwidth) {
  //     selfCpu = 0
  //   } else {
  //     selfCpu = parseFloat(getMinerRes.self_delegated_bandwidth.cpu_weight).toFixed(2)
  //   }

  //   if (!getMinerRes.self_delegated_bandwidth) {
  //     selfNet = 0
  //   } else {
  //     selfNet = parseFloat(getMinerRes.self_delegated_bandwidth.net_weight).toFixed(2)
  //   }

  //   if (!getMinerRes.wax_balance) {
  //     wax = 0
  //   } else {
  //     wax = parseFloat(getMinerRes.wax_balance).toFixed(2)
  //   }

  //   if (!getMinerRes.land) {
  //     getMinerRes.land = {
  //       data: {
  //         name: 'not register',
  //         commission: 10000
  //       }
  //     }
  //   }

  //   if (!getMinerRes.last_mine) {
  //     getMinerRes.last_mine = "2020-01-01T00:00:00"
  //   }

  //   if (!getMinerRes.inventory) {
  //     getMinerRes.inventory = [];
  //   }

  //   const now = Date.now();
  //   const last_mine_ms = Date.parse(getMinerRes.last_mine + '.000Z');
  //   let waitsec = parseInt((last_mine_ms + (getMinerRes.params.delay * 1000) - now) / 1000);
  //   let latesec = parseInt(now - (last_mine_ms + (getMinerRes.params.delay * 1000))) / 1000;
  // })
  return returnAllResult;
};

const historyEndpoints = [
  "https://wax.blokcrafters.io",
  "https://wax-hyperion.alcor.exchange",
  "https://history-wax-mainnet.wecan.dev",
  "https://wax.cryptolions.io",
  "https://wax.dapplica.io",
  "https://wax-history.eosdac.io",
  "https://wax.eosdublin.io",
  "https://hyperion.wax.detroitledger.tech",
  "https://wax.eosphere.io",
  "https://api.wax.greeneosio.com",
  "https://api.wax.liquidstudios.io",
  "https://wax.hivebp.io",
  "https://api.waxsweden.org",
];

let apiuse = 0;

const getHistoryEndpoint = () => {
  if (apiuse > historyEndpoints.length - 1) {
    apiuse = 0;
  }
  const returnApi = historyEndpoints[apiuse];
  apiuse++;
  return returnApi;
}

const getTransaction = (api, tx) => axios.post(
  `${api}/v1/history/get_transaction`, {
  id: tx
}
).catch(e => { });

const lastMineState = {};

const Monitor = () => {

  const [searchParams] = useSearchParams();
  const GoogleId = searchParams.get('googleid');
  const PortName = searchParams.get('port');
  document.title = PortName;

  const getLastMine = async () => {
    for (const data of queryData) {
      try {
        const getCacheTLM = localStorage.getItem(`${data.miner}`);
        if (getCacheTLM) {
          const [lasttx, bounty] = getCacheTLM.split(":");
          if (lasttx !== data.last_mine_tx) {
            if (!lastMineState[data.last_mine_tx]) {
              const gettx = await getTransaction(getHistoryEndpoint(), data.last_mine_tx);
              const findNotify = gettx.data.traces.filter(action => action.receipt.receiver === "notify.world");
              if (findNotify.length > 0) {
                const bounty = findNotify[0].act.data.bounty;
                lastMineState[data.last_mine_tx] = bounty;
                localStorage.setItem(`${data.miner}`, `${data.last_mine_tx}:${bounty}`);
              }
            }
          }
        } else {
          if (!lastMineState[data.last_mine_tx]) {
            const gettx = await getTransaction(getHistoryEndpoint(), data.last_mine_tx);
            const findNotify = gettx.data.traces.filter(action => action.receipt.receiver === "notify.world");
            if (findNotify.length > 0) {
              const bounty = findNotify[0].act.data.bounty;
              lastMineState[data.last_mine_tx] = bounty;
              localStorage.setItem(`${data.miner}`, `${data.last_mine_tx}:${bounty}`);
            }
          }
        }
      } catch (error) {

      }
    }
  }

  const [portData, setPortData] = useState(false);
  const [queryData, setQueryData] = useState([]);
  const [headerData, setHeaderData] = useState({
    totalTLM: 0,
    totalClaim: 0,
    overAllTLM: 0,
    totalWax: 0,
    refundingWax: 0,
    overAllWax: 0,
    stakeAll: 0,
    botAlive: 0,
    botFlag: 0,
    timeRangeText: '00.00-00.00',
    portName: '',
    autoClaim: '',
    claimAt: 0,
    mainStake: '',
    transferTo: '',
    version: '0.0.0'
  });

  useEffect(
    () => {
      if (portData) {
        // console.log(portData, GoogleId, PortName)
        getLastMine();
        if (portData.data['statics']) {
          if (portData.data['statics'][PortName]) {
            const stats = portData.data['statics'][PortName];
            let totalStake = 0;
            let totalwax = 0;
            for (const miner of portData.data['ports'][PortName]) {
              if (miner.id !== "MAINSTAKE") {
                const miner_stake = miner.self_delegated_bandwidth;
                if (miner_stake) {
                  totalStake += parseFloat(miner_stake.cpu_weight);
                }
                if (miner.wax_balance) {
                  totalwax += parseFloat(miner.wax_balance);
                }
              }
            }

            totalStake = parseFloat(totalStake).toFixed(2);
            totalwax = parseFloat(totalwax).toFixed(2);

            const totalbot = portData.data['ports'][PortName].filter(b => b.id !== "MAINSTAKE").length;
            const totalflag = portData.data['ports'][PortName].filter(b => b.flagged).length;

            setHeaderData({
              totalTLM: stats.t_tlm,
              totalClaim: stats.c_tlm,
              overAllTLM: parseFloat(stats.c_tlm + stats.t_tlm).toFixed(2),
              totalWax: stats.b_wax,
              refundingWax: 0,
              overAllWax: totalwax,
              stakeAll: totalStake,
              botAlive: totalbot,
              botFlag: totalflag,
              timeRangeText: stats.cycle,
              portName: PortName,
              autoClaim: 'on',
              claimAt: stats.claim,
              mainStake: stats.mainstake ? 'on' : 'off',
              transferTo: stats.transferTo,
              version: stats.version ? stats.version : 'not found'
            })
          }
        }
        if (portData.data['ports']) {
          if (portData.data['ports'][PortName]) {
            setQueryData(portData.data['ports'][PortName]);
          }
        }
      }
    },
    [portData]
  );

  const [num, setNum] = useState(0);

  useEffect(
    () => {
      if (GoogleId && PortName) {
        axios.get(`https://anonymous-aw.com/api/monitor`, {
          headers: {
            'x-user-id': GoogleId
          }
        }).then((res) => {
          setPortData(res.data);
        }).catch((err) => console.log(err));
      }
    },
    []
  );

  useEffect(() => {
    const intervalId = setInterval(async () => {
      setNum(num + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [num]);

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(`wss://anonymous-aw.com/ws?googleid=${GoogleId}&port=${PortName}`, {
    onOpen: () => console.log('open connection to server'),
    onMessage: (message) => {
      try {
        message = JSON.parse(message.data);
        switch (message.type) {
          case "message": {
            if (message.status === 'success') {
              toast.success(message.message, { duration: 5000 });
            } else if (message.status === 'loading') {
              toast.loading(message.message, { duration: 3000 });
            } else if (message.status === 'error') {
              toast.error(message.message, { duration: 10000 });
            }
            break;
          }
          case "monitorData": {
            if (message.data['statics'][PortName] && message.data['ports'][PortName]) {
              setPortData(message);
            } else {
              setQueryData([]);
            }
            break;
          }
          default: break;
        }
      } catch (error) {
        console.log(error)
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  const data = queryData.map(data => formatGetMinerData(data));
  // const results = resultData(queryData);

  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelectChange = (ids) => setSelectedIds(ids);

  return (
    <div className="monitor">
      <Header headerData={headerData} />
      <CustomTable data={data} onSelect={handleSelectChange}></CustomTable>
      <div className="dropdown-container">
        <DropdownAction idChoose={selectedIds} transferTo={headerData.transferTo} serverSendMessage={sendMessage} />
      </div>
    </div>
  );
};


export default Monitor;
