import ProgressBar from 'react-bootstrap/ProgressBar';

function Prog({percent}) {
  let color
  if (percent>90)
    color = 'danger';
  else if (percent>60){
    color = 'warning';
  } else {
    color = 'success'
  }
  return (
    <div>
      <ProgressBar variant={color} animated now={percent} max={100} label={`${parseFloat(percent).toFixed(0)}%`} style={{backgroundColor:'#686868', minWidth:'2rem', height: '0.5rem', fontSize: '8px'}}/>
    </div>
  );
}

export default Prog;